import React from "react";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaInstagram,
  FaFacebook,
  FaPinterest,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import { FaHouse, FaXTwitter } from "react-icons/fa6";

const ContactDetails = () => {
  return (
    <>
      <p>
        Contact us for more informatin or to receive a quote. We look forward to
        hearing from you.
      </p>
      <div className="flex flex--icons">
        <FaPhoneAlt style={{ marginRight: "10px" }} />
        <a href="tel:07541081857">
          <h3>07541 081 857</h3>
        </a>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <a
          href="https://wa.me/447541081857"
          className="btn"
          style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}
        >
          <FaWhatsapp width={25} height={25} /> Any questions? WhatsApp us
        </a>
      </div>
      <div className="flex flex--icons">
        <FaEnvelope style={{ marginRight: "10px" }} />
        <a href="mailto:info@cardiffgardenrooms.co.uk">
          <h3>info@cardiffgardenrooms.co.uk</h3>
        </a>
      </div>
      <div className="flex flex--icons" style={{ alignItems: "start" }}>
        <FaHouse style={{ marginRight: "10px", flexShrink: 0 }} />
        <h3 style={{ fontWeight: "400" }}>
          Regus House, Malthouse Avenue, Cardiff Gate Business Park, Cardiff,
          CF23 8RU
        </h3>
      </div>
      <a href="https://www.instagram.com/cardiffgardenrooms/">
        <FaInstagram style={{ marginRight: "10px" }} size={35} />
      </a>
      <a href="https://www.facebook.com/cardiffgardenrooms">
        <FaFacebook style={{ marginRight: "10px" }} size={35} />
      </a>
      <a href="https://www.x.com/cdfgardenrooms">
        <FaXTwitter style={{ marginRight: "10px" }} size={35} />
      </a>
      <a href="https://www.pinterest.co.uk/cardiffgardenrooms/">
        <FaPinterest style={{ marginRight: "10px" }} size={35} />
      </a>
      <a href="https://g.co/kgs/MBQum3">
        <SiGooglemybusiness style={{ marginRight: "10px" }} size={35} />
      </a>

      <a href="https://www.linkedin.com/company/cardiff-garden-rooms/">
        <FaLinkedin style={{ marginRight: "10px" }} size={35} />
      </a>
    </>
  );
};

export default ContactDetails;
